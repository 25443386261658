import storage from "@/plugins/storage";
import { userAgent } from "@/tests/unit/setup";
import { Route } from "vue-router";
import type ExperimentServiceType from "./ExperimentService";
import { CookiesKeys } from "@/enums/Storage";
import api from "@/api";
import * as Sentry from "@sentry/browser";
import {
  IExperimentsByPercentage,
  IAdminOptionsResponse,
  IExperimentsByUserId,
} from "@/models/Experiments";

jest.mock("@/services/MyRentalsConfig");

const experimentsMock: IExperimentsByPercentage = {
  MYH_TEST: {
    id: "fakeId",
    name: "MYH_TEST",
    options: null,
    lastReset: [1, 1],
    adminOptionsId: 1,
    pathPattern: /^\/test/,
  },
  MYH_TEST2: {
    id: "fakeId2",
    name: "MYH_TEST2",
    options: null,
    adminOptionsId: 1,
    lastReset: [1, 1],
    pathPattern: /^\/xx/,
  },
};

const experimentsByUserIdMock: IExperimentsByUserId = {
  USER_ID_TEST: {
    id: "fakeByUserId",
    name: "fakeByUserId",
    users: [718],
    variant: "B",
  },
};

const experiments2Mock: IExperimentsByPercentage = {
  MYH_TEST: {
    id: "fakeId",
    name: "MYH_TEST",
    options: null,
    lastReset: [1, 1],
    paths: ["/test"],
  },
};

const optionsFromAdmin: IAdminOptionsResponse = {
  active: true,
  defaultValue: false,
  constrains: [
    {
      value: true,
      countryIsoCode: null,
      locationIds: [],
      userIds: [],
      originIds: [],
      userPercentage: 50,
    },
  ],
};

const routes: Route[] = [
  {
    name: "route-test",
    hash: "",
    query: {},
    params: {},
    path: "/test",
    fullPath: "",
    matched: [],
  },
  {
    name: "route-xx",
    hash: "",
    query: {},
    params: {},
    path: "/xx",
    fullPath: "",
    matched: [],
  },
];
jest.mock("@/api");

let ExperimentService: typeof ExperimentServiceType;
let ExperimentService2: typeof ExperimentServiceType;
describe("ExperimentService", () => {
  const mockedUserId = { value: experimentsByUserIdMock.USER_ID_TEST.users[0] };
  const mockedUserVariant = experimentsByUserIdMock.USER_ID_TEST.variant;
  describe("First mock", () => {
    beforeAll(async () => {
      jest.mock("@/constants/Experiments", () => ({
        EXPERIMENTS_BY_PERCENTAGE: experimentsMock,
        EXPERIMENTS_BY_USER_ID: experimentsByUserIdMock,
      }));

      ExperimentService = (await import("./ExperimentService")).default;
    });
    afterAll(async () => {
      jest.clearAllMocks();
      jest.resetModules();
    });
    it("Should correctly get the variant from cookies & user id", async () => {
      //given
      const mockedTimeStamp = 1686825754335;
      const mockedVariant = `0|${mockedTimeStamp}`;
      optionsFromAdmin.constrains[0].userPercentage = 50;
      spyOn(storage, "setCookiesItemWithId");
      spyOn(Date.prototype, "getTime").and.returnValue(mockedTimeStamp);
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(mockedVariant);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue("user agent");
      Object.defineProperty(ExperimentService, "currentUserId", mockedUserId);

      //when
      await ExperimentService.setExperiments(routes[0]);

      //then
      expect(storage.getCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        experimentsMock.MYH_TEST.id
      );
      expect(storage.setCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        experimentsMock.MYH_TEST.id,
        mockedVariant
      );
      expect(ExperimentService.allExperimentVariants["fakeId"]).toEqual("A");
      expect(ExperimentService.allExperimentVariants["fakeByUserId"]).toEqual(
        mockedUserVariant
      );
    });

    describe("Should set a variant when it is not in cookies", () => {
      beforeEach(() => {
        //given
        optionsFromAdmin.constrains[0].userPercentage = 50;
        spyOn(storage, "setCookiesItemWithId");
        spyOn(storage, "getCookiesItem").and.returnValue(null);
        spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
        spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
          optionsFromAdmin
        );
        userAgent.mockReturnValue("user agent");
      });

      it("for first experiment", async () => {
        //when
        await ExperimentService.setExperiments(routes[0]);
        //then
        expect(storage.getCookiesItemWithId).toHaveBeenCalledWith(
          CookiesKeys.EXPERIMENT,
          experimentsMock.MYH_TEST.id
        );
        expect(api.experiments().getExperimentOptions).toHaveBeenCalledWith(
          experimentsMock.MYH_TEST.adminOptionsId
        );
        expect(storage.setCookiesItemWithId).toHaveBeenCalled();
        expect(["A", "B"]).toContain(
          ExperimentService.allExperimentVariants[experimentsMock.MYH_TEST.id]
        );
      });

      it("for second experiment", async () => {
        //when
        await ExperimentService.setExperiments(routes[1]);
        //then
        expect(storage.getCookiesItemWithId).toHaveBeenCalledWith(
          CookiesKeys.EXPERIMENT,
          experimentsMock.MYH_TEST2.id
        );
        expect(api.experiments().getExperimentOptions).toHaveBeenCalledWith(
          experimentsMock.MYH_TEST2.adminOptionsId
        );
        expect(storage.setCookiesItemWithId).toHaveBeenCalled();
        expect(["A", "B"]).toContain(
          ExperimentService.allExperimentVariants[experimentsMock.MYH_TEST2.id]
        );
      });
    });

    it("Should set the A variant when options are 1 to 0", async () => {
      //given
      const mockedTimeStamp = 1686825754335;
      optionsFromAdmin.constrains[0].userPercentage = 100;
      spyOn(storage, "setCookiesItemWithId");
      spyOn(Date.prototype, "getTime").and.returnValue(mockedTimeStamp);
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue("user agent");

      //when
      await ExperimentService.setExperiments(routes[0]);

      //then
      expect(storage.getCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        experimentsMock.MYH_TEST.id
      );
      expect(api.experiments().getExperimentOptions).toHaveBeenCalledWith(
        experimentsMock.MYH_TEST.adminOptionsId
      );
      expect(storage.setCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        "fakeId",
        `0|${mockedTimeStamp}`
      );
      expect(ExperimentService.allExperimentVariants["fakeId"]).toEqual("A");
    });

    it("Should set the B variant when options are 0 to 1", async () => {
      optionsFromAdmin.constrains[0].userPercentage = 0;
      //given
      const mockedTimeStamp = 1686825754335;
      spyOn(storage, "setCookiesItemWithId");
      spyOn(Date.prototype, "getTime").and.returnValue(mockedTimeStamp);
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue("user agent");

      //when
      await ExperimentService.setExperiments(routes[0]);

      //then
      expect(storage.getCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        experimentsMock.MYH_TEST.id
      );
      expect(api.experiments().getExperimentOptions).toHaveBeenCalledWith(
        experimentsMock.MYH_TEST.adminOptionsId
      );
      expect(storage.setCookiesItemWithId).toHaveBeenCalled();
      expect(ExperimentService.allExperimentVariants["fakeId"]).toEqual("B");

      expect(storage.setCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        "fakeId",
        `1|${mockedTimeStamp}`
      );
    });

    it("Should not set any variant if it is a rf test", async () => {
      //given
      spyOn(storage, "setCookiesItemWithId");
      spyOn(storage, "getCookiesItem").and.returnValue(true);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue("user agent");

      //when
      await ExperimentService.setExperiments(routes[0]);
      expect(storage.getCookiesItemWithId).not.toHaveBeenCalled();

      //then
      expect(api.experiments().getExperimentOptions).not.toHaveBeenCalled();
      expect(storage.setCookiesItemWithId).not.toHaveBeenCalled();
      expect(ExperimentService.allExperimentVariants["fakeId"]).toBeUndefined();
    });

    it("Should not assign any variant if it is googleBot", async () => {
      //given
      spyOn(storage, "setCookiesItemWithId");
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue("Googlebot");

      //when
      await ExperimentService.setExperiments(routes[0]);

      //then
      expect(storage.getCookiesItemWithId).not.toHaveBeenCalled();
      expect(api.experiments().getExperimentOptions).not.toHaveBeenCalled();
      expect(storage.setCookiesItemWithId).not.toHaveBeenCalled();
      expect(ExperimentService.allExperimentVariants["fakeId"]).toBeUndefined();
    });

    it("Should not set any variant if path does not match", async () => {
      //given
      const invalidRoute = { ...routes[0] };
      invalidRoute.path = "/";
      spyOn(storage, "setCookiesItemWithId");
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue(
        "Mozilla/5.0 (Linux; Android 8.0; Pixel 2 Build/OPD3.170816.012; Storebot-Google/1.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Mobile Safari/537.36"
      );

      //when
      await ExperimentService.setExperiments(invalidRoute);

      //then
      expect(storage.getCookiesItemWithId).not.toHaveBeenCalled();
      expect(api.experiments().getExperimentOptions).not.toHaveBeenCalled();
      expect(storage.setCookiesItemWithId).not.toHaveBeenCalled();
      expect(ExperimentService.allExperimentVariants["fakeId"]).toBeUndefined();
    });

    it("Should not assign any variant if it is bingbot", async () => {
      //given
      spyOn(storage, "setCookiesItemWithId");
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.returnValue(
        optionsFromAdmin
      );
      userAgent.mockReturnValue("bingbot");

      //when
      await ExperimentService.setExperiments(routes[0]);

      //then
      expect(storage.getCookiesItemWithId).not.toHaveBeenCalled();
      expect(api.experiments().getExperimentOptions).not.toHaveBeenCalled();
      expect(storage.setCookiesItemWithId).not.toHaveBeenCalled();
      expect(ExperimentService.allExperimentVariants["fakeId"]).toBeUndefined();
    });

    it("Should not set any variant if there is an API error", async () => {
      //given
      spyOn(storage, "setCookiesItemWithId");
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions").and.throwError(
        "Api Error"
      );
      spyOn(console, "error");
      spyOn(Sentry, "captureException");
      userAgent.mockReturnValue("user agent");

      //when
      await ExperimentService.setExperiments(routes[0]);

      //then
      expect(storage.getCookiesItemWithId).toHaveBeenCalledWith(
        CookiesKeys.EXPERIMENT,
        experimentsMock.MYH_TEST.id
      );
      expect(api.experiments().getExperimentOptions).toHaveBeenCalledWith(
        experimentsMock.MYH_TEST.adminOptionsId
      );
      expect(storage.setCookiesItemWithId).not.toHaveBeenCalled();
      expect(ExperimentService.allExperimentVariants["fakeId"]).toBeUndefined();
      expect(console.error).toHaveBeenCalled();
      expect(Sentry.captureException).toHaveBeenCalledWith(
        "Error getting experiment options: Error: Api Error"
      );
    });
  });
  describe("Second mock", () => {
    beforeAll(async () => {
      jest.mock("@/constants/Experiments", () => ({
        EXPERIMENTS_BY_PERCENTAGE: experiments2Mock,
        EXPERIMENTS_BY_USER_ID: experimentsByUserIdMock,
      }));
      ExperimentService2 = (await import("./ExperimentService")).default;
    });
    it("Should thow an error because no options are set and getFromAdmin is false", async () => {
      //given
      spyOn(storage, "setCookiesItemWithId");
      spyOn(storage, "getCookiesItem").and.returnValue(null);
      spyOn(storage, "getCookiesItemWithId").and.returnValue(null);
      spyOn(api.experiments(), "getExperimentOptions");
      spyOn(console, "error");
      userAgent.mockReturnValue("user agent");

      //when
      await ExperimentService2.setExperiments(routes[0]);

      //then
      expect(api.experiments().getExperimentOptions).not.toHaveBeenCalled();
      expect(storage.setCookiesItemWithId).not.toHaveBeenCalled();
      expect(console.error).toHaveBeenCalled();
      expect(
        ExperimentService2.allExperimentVariants["fakeId"]
      ).toBeUndefined();
    });
  });
});
